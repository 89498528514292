<template>
  <el-dialog :title="title" :visible.sync="show" class="choose-selectedPlan-importSuccess">
    <el-main>
      <div class="main flex">
        <i class="el-icon-check"></i>
        <div class="text1">导入完成</div>
        <div class="text2">
          导入数据共{{sum}}条，成功{{success}}条，
          <span class="fail">失败{{fail}}条</span>
          （重复{{repeat}}条，
          <span class="detail">点击查看详情</span>
          ）用时{{consumeTime}}秒
        </div>
        <el-button>关闭</el-button>
      </div>
    </el-main>
  </el-dialog>
</template>

<script>
export default {
  // 导入成功
  name: 'importSuccess',
  props: {
    title: {
      tyle: String,
    },
  },
  components: {},
  data() {
    return {
      show: false,
      sum: 1000,
      success: 998,
      fail: 2,
      repeat: 2,
      consumeTime: 24,
    };
  },
  methods: {
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    flex-direction: column;
    .el-icon-check {
      font-size: 60px;
      background: #52c41a;
      border-radius: 50%;
      color: white;
      padding: 10px;
      font-weight: bold;
    }
    .text1 {
      color: $h2;
      font-size: 16px;
      font-weight: 900;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .text2 {
      margin-bottom: 50px;
    }
    .fail {
      color: #ec808d;
    }
    .detail {
      color: #0079fe;
      text-decoration: underline;
      cursor: pointer;

      &:active {
        opacity: 0.7;
      }
    }
  }
}
</style>
